<template>
    <div style="background-color: #fff;">
        <div style="padding: 16px;display: flex;justify-content: space-between;">
            <div class="searchItem">
                <slot name="search"></slot>
                <el-button style="margin-left: 10px;" type="primary" @click="onSearch" size="small" icon="el-icon-search" plain>查询</el-button>
                <el-button @click="resetSearch" size="small" icon="el-icon-refresh" plain>重置</el-button>                
            </div>
            <div>
                <slot name="bar"></slot>
                <el-button v-if="outBt > 0" @click="out" size="small" icon="el-icon-document" plain>导出</el-button>
                <el-button @click="refresh" size="small" icon="el-icon-refresh-right" plain>刷新</el-button> 
            </div>
        </div>
        <el-table v-loading="loading" :data="list">
            <slot name="column"></slot>
        </el-table>
        <el-pagination v-if="total >= size" style="padding: 10px;display: flex;justify-content: flex-end;" :current-page.sync="page" :page-sizes="[20, 50, 100, 500]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="getlist" @size-change="getlist">
        </el-pagination>        
    </div>
</template>

<script setup>
import { ref, onMounted, defineExpose } from 'vue'
import XLSX from "xlsx"
import store from '@/store'

const props = defineProps({
    'url':Function, 
    "checkFun":Function,
    "value":Object, 
    "baseSearch":{
        type:Object,
        default:()=>({})
    },
    "outBt":{
        type:Number,
        default:1
    },
});
const emit = defineEmits(['input'])
const total = ref(0);
const page = ref(1);
const size = ref(20);
const list = ref([]);
const loading = ref(false);

onMounted(()=>{
    //console.log(props.baseSearch);
    getlist();
});

/** 获取数据列表 */
function getlist(){
    if(loading.value) return;
    let param = {
        token: store.state.user.token,
        page: page.value,
        size: size.value,
        ...props.value,
        ...props.baseSearch
    };
    loading.value = true;
    props.url(param).then((res)=>{
        total.value = res.total;
        if(props.checkFun) props.checkFun(res.data);
        list.value = res.data;
        loading.value = false;
    }).catch(()=>{
        loading.value = false;
    });
}
/** 搜索 */
function onSearch(){
    page.value = 1;
    getlist();
}
/** 重置搜索 */
function resetSearch(){
    emit('input', {});
    setTimeout(getlist, 0);
}
/** 刷新数据 */
function refresh(){
    getlist();
}
/** 导出数据 */
function out(e){
    if(props.outBt == 1){ //导出当前页
        let workbook = XLSX.utils.book_new();
        //console.log(e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("el-table__body")[0]);
        let sheet = XLSX.utils.table_to_sheet(e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("el-table__header")[0]);
        let data = XLSX.utils.table_to_sheet(e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("el-table__body")[0]);
        let ln = data["!ref"].split(':');
        let cells = XLSX.utils.decode_cell(ln[1]);
        //console.log(cells);
        let arr = [];
        for(let r=0;r<=cells.r;r++){
            let val = [];
            for(let c=0;c<=cells.c;c++){
                //console.log(r,c,XLSX.utils.encode_cell({r:r, c:c}));
                val.push(data[XLSX.utils.encode_cell({r:r, c:c})]);
            }
            arr.push(val);
        }
        XLSX.utils.sheet_add_aoa(sheet, arr, {origin:-1});
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1", true);
        //console.log(workbook);
        XLSX.writeFile(workbook, "out.xlsx");        
    }else if(props.outBt == 2){ //导出前1w条记录

    }
}
defineExpose({
    getlist
});
</script>

<style lang="scss" scoped>
.searchItem{
    display: flex;
    gap:8px;
    .el-input{
        width: 200px;
    }
}
</style>