<template>
	<div class="page">
		<el-tabs v-model="activeName" @tab-click="tabClick">
			<el-tab-pane label="汇总统计" name="first">
				<tableview :url="API.Market.yearbill.index" v-model="searchHz" :outBt="0">
					<template #search>
						<el-date-picker size="small" v-model="searchHz.tms" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
					</template>
					<template #column>
						<el-table-column label="页面访问统计">
							<el-table-column prop="pg_name" label="页面名称"></el-table-column>
							<el-table-column prop="pg_tm" label="平均浏览时长"></el-table-column>
							<el-table-column prop="pg_exit" label="退出率">
								<template slot-scope="{row}">{{ row.pg_exit }}%</template>
							</el-table-column>
						</el-table-column>
						<el-table-column label=""></el-table-column>
						<el-table-column label="其它统计">
							<el-table-column prop="u_name" label="统计项目"></el-table-column>
							<el-table-column prop="u_value" label="">
								<template slot-scope="{row}">{{ row.u_value }}{{ "u_value" in row?"%":'' }}</template>
							</el-table-column>
						</el-table-column>
					</template>
				</tableview>
			</el-tab-pane>
			<el-tab-pane label="访问记录" name="second">
				<tableview :url="API.Market.yearbill.visitlist" v-model="searchValue">
					<template #search>
						<el-date-picker size="small" v-model="searchValue.tms" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
						<el-input size="small" v-model="searchValue.phone" placeholder="输入用户手机号" clearable></el-input>
					</template>
					<template #column>
						<el-table-column prop="phone" label="用户手机号"></el-table-column>
						<el-table-column prop="tm" label="访问时间"></el-table-column>
						<el-table-column prop="pg_tm" label="封面页"></el-table-column>
						<el-table-column prop="pg1_tm" label="账单页一"></el-table-column>
						<el-table-column prop="pg2_tm" label="账单页二"></el-table-column>
						<el-table-column prop="pg3_tm" label="账单页三"></el-table-column>
						<el-table-column prop="pg4_tm" label="账单页四"></el-table-column>
						<el-table-column prop="pg_end" label="结束页"></el-table-column>
						<el-table-column label="感谢红包">
							<template slot-scope="{row}">{{ row.coupon?'已领取':'' }}</template>
						</el-table-column>
						<el-table-column label="能量值">
							<template slot-scope="{row}">{{ row.share?'已领取':'' }}</template>
						</el-table-column>
					</template>
				</tableview>
			</el-tab-pane>
			<el-tab-pane label="优惠券配置" name="third">
				<el-form :model="cfgData" ref="cfgForm" label-width="100px" class="form">
					<el-form-item label="未充电优惠券" prop="coupon_id">
						<el-select v-model="cfgData.coupon_id" size="mini">
							<el-option v-for="(item) in couponList" :value="item.coupon_id" :label="item.coupon_name" :key="item.coupon_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="分享得能量值" prop="energy">
						<el-input-number type="number" :min="1" :max="100" :step="10" :precision="0" v-model="cfgData.energy" style="width: 108px;" class="input" size="mini" placeholder="能量值">
						</el-input-number>
					</el-form-item>
					<el-form-item label="感谢优惠券" prop="thank">
						<el-button icon="el-icon-plus" type="primary" size="mini" @click="cfgAdd">增加券</el-button>
						<div v-for="(item,idx) in cfgData.thank">
							<el-select v-model="cfgData.thank[idx][0]" size="mini">
								<el-option v-for="(item) in couponList" :value="item.coupon_id" :label="item.coupon_name" :key="item.coupon_id"></el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="10" :step="1" :precision="0" v-model="cfgData.thank[idx][1]" style="width: 108px;" class="input" size="mini" placeholder="数量">
							</el-input-number>
							<el-button icon="el-icon-delete" type="danger" size="mini" @click="cfgDel"></el-button>
						</div>
					</el-form-item>
				</el-form>
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveCfg()">保 存</el-button>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import tableview from '@/components/tableview.vue'

const activeName = ref("first");
const searchValue = ref({});
const couponList = ref([]); //优惠券列表
onMounted(()=>{
	API.Market.Coupon.minlist({token: store.state.user.token}).then((res)=>{
		couponList.value = res;				
	});
	API.System.Config.detail({token: store.state.user.token, types:"yearbill"}).then((res)=>{
		if(res) cfgData.value = res;				
	});
});

//卡片点击
function tabClick(){
}

// 汇总统计 
const searchHz = ref({});

// 优惠券配置
const cfgData = ref({thank:[]});
function cfgAdd(){
	cfgData.value.thank.push(['',1]);
}
function cfgDel(idx){
	cfgData.value.thank.splice(idx, 1);
}
// 保存配置信息
function saveCfg(){
	API.System.Config.edit({token: store.state.user.token, types:"yearbill", cfg:JSON.stringify(cfgData.value)}).then((res)=>{
		Notification({
			title: '成功',
			message: '保存成功',
			type: 'success'
		})
	});
}

</script>

<style lang="scss" scoped>
.el-tab-pane {
	min-height: 300px;
}
.countBox{
	background-color: #fff;
	margin-top: 8px;
	display: flex;
	padding: 20px 80px;
	div{
		margin-right: 160px;
	}
	a{
		font-size: 24px;
		font-weight: bold;
	}
}
</style>
